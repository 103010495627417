<template>
  <div class="report-wrapper">
    <div class="header">
      <div class="filters">
        <div class="assignees">
          <assignees :value="assignees" label="Assignees" :disabled="loading" @change="asssigneesChanged" />
          <Button class="robot-icon" type="icon" :disabled="!canAddBots || loading" title="Include all bots" @click.stop="addAllBots"
            ><hub-icon name="robot"
          /></Button>
        </div>
        <date-field
          :value="from"
          label="From"
          :calendar-avaliable="false"
          :disabled="loading"
          :limits="{ days: { max: '0' } }"
          @change="fromChanged"
        />
        <date-field
          v-model:value="to"
          label="To"
          :calendar-avaliable="false"
          :disabled="loading"
          :limits="{ days: { max: '0' } }"
          @change="toChanged"
        />
        <Button class="reset-button" type="text" :disabled="loading" @click.stop="reset">Reset</Button>
      </div>
      <div>
        <hub-checkbox :value="includeEarlier" label="Show all status changes" :disabled="loading" @change="changeIncludeEarlier" />
      </div>
    </div>
    <template v-if="!isGetCollectionRequestPending">
      <div v-if="collection && collection.length && assignees?.length">
        <div v-for="entry in sortedCollection" :key="entry._id">
          <div class="row" @click="toggleExpanded(entry._id)">
            <hub-icon :name="expanded.includes(entry._id) ? 'chevron-up' : 'chevron-down'" />
            <span v-if="entry._id === 'created_milestones'">
              Milestone{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span
            >
            <span v-else-if="entry._id === 'created_tasks'"> Task{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span>
            <span v-else>
              <b>{{ entry._id }}</b> ({{ entry.tasks.length }})
            </span>
          </div>
          <div v-if="expanded.includes(entry._id)" class="expanded-report-row">
            <div v-for="task in entry.tasks" :key="task.id">
              <template v-if="entry._id === 'created_milestones'">
                <milestone-row :milestone="task" />
              </template>
              <template v-else> <task-row :task="task"/></template>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loading-wrapper">No activities</div>
    </template>
    <div v-else class="loading-wrapper">
      <hub-icon name="loading" spin size="lg"></hub-icon>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import MilestoneRow from './components/MilestoneRow.vue';
import TaskRow from './components/TaskRow.vue';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import Button from '@/components/common/Button';

export default {
  components: {
    'hub-icon': Icon,
    'hub-checkbox': Checkbox,
    MilestoneRow,
    TaskRow,
    Assignees,
    DateField,
    Button
  },
  data() {
    return {
      expanded: ['to do'],
      includeEarlier: false,
      assignees: [],
      from: new Date(),
      to: new Date(),
      extendedFiltersExpanded: false,
      ready: true
    };
  },
  computed: {
    ...mapState({
      collection: s => s.reports.myTasksCount.collection,
      isGetCollectionRequestPending: s => s.reports.myTasksCount.isGetCollectionRequestPending,
      email: s => s.identity.email,
      bots: s => s.bots
    }),
    canAddBots() {
      if (!this.bots?.length) {
        return false;
      }

      return this.bots.find(bot => !this.assignees.includes(bot));
    },
    loading() {
      return this.isGetCollectionRequestPending;
    },
    sortedCollection() {
      const result = [];
      if (!this.collection) {
        return result;
      }
      const done = this.collection.find(item => item._id === 'done');
      const created_milestones = this.collection.find(item => item._id === 'created_milestones');
      const created_tasks = this.collection.find(item => item._id === 'created_tasks');

      const statuses = this.collection.filter(item => item._id !== 'done' && item._id !== 'created_milestones' && item._id !== 'created_tasks');
      if (done) {
        result.push(done);
      }
      result.push(...statuses.sort((a, b) => b.tasks.length - a.tasks.length));
      if (created_milestones) {
        result.push(created_milestones);
      }
      if (created_tasks) {
        result.push(created_tasks);
      }
      return result;
    }
  },
  created() {
    this.assignees = [this.email];
  },
  methods: {
    reset() {
      this.ready = false;

      this.assignees = [this.email];
      this.from = new Date();
      this.to = new Date();

      this.ready = true;

      this.buildReport();
    },
    fromChanged(value) {
      this.from = value;
      this.buildReport();
    },
    toChanged(value) {
      this.to = value;
      this.buildReport();
    },
    asssigneesChanged(value) {
      this.assignees = [...value];
      this.buildReport();
    },
    async buildReport() {
      if (!this.ready) {
        return;
      }

      try {
        this.ready = false;

        await this.$store.dispatch('reports/myTasksCount/getCollection', {
          assignees: this.assignees,
          from: this.from && this.from.toLocaleDateString(),
          to: this.to && this.to.toLocaleDateString(),
          includeEarlier: this.includeEarlier
        });
      } finally {
        this.ready = true;
      }
    },
    async addAllBots() {
      if (this.canAddBots) {
        const notIncludedBots = this.bots.filter(b => !this.assignees.includes(b));
        this.assignees = [...this.assignees, ...notIncludedBots];
        this.buildReport();
      }
    },
    toggleExpanded(id) {
      const index = this.expanded.findIndex(i => i === id);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(id);
      }
    },
    changeIncludeEarlier() {
      this.includeEarlier = !this.includeEarlier;
      this.buildReport();
    }
  }
};
</script>

<style lang="scss" scoped>
.report-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 0 15px;

  .header {
    display: grid;

    .filters {
      display: grid;
      grid-template-columns: 2fr 0.5fr 0.5fr auto auto;
      align-items: start;
      gap: 10px;
      padding: 15px 0;

      .assignees {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;
        align-items: start;

        .robot-icon {
          margin-top: 15px;
        }
      }

      .reset-button {
        margin-top: 15px;
      }
    }
  }

  .row {
    font-size: 1rem;
    padding: 15px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
  .expanded-report-row {
    padding: 15px;
  }
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
