<template>
  <div class="task-row">
    <router-link :to="`/inventions/${task.inventionId}?task=${task.id}`" title="Go to task" target="_blank" @click.stop>
      <svg viewBox="0 0 24 24" style="width: 12px; height: 12px; display: inline-block; transform: translateY(1px)">
        <path
          data-v-9bab0cdc=""
          fill="currentColor"
          d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
        ></path>
      </svg>
    </router-link>
    <div>
      {{ task.title }}
    </div>
    <username-list :value="task.assignees" />
    <locale-due-at :due-at="task.dueAt" emtpy-due-at-text="no due at" />
  </div>
</template>
<script>
import LocaleDueAt from './LocaleDueAt.vue';
import UsernameList from './UsernameList.vue';
export default {
  components: {
    LocaleDueAt,
    UsernameList
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.task-row {
  display: grid;
  grid-gap: 0;
  border-bottom: 1px solid var(--theme-highlight);
  background: var(--theme-surface);
  align-content: center;
  align-items: center;
  min-height: 4em;
  grid-template-columns: 30px 3fr 1fr 65px;
  padding: 5px;
}
</style>
